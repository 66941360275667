import React from 'react';
import { PageHero, PageWithSubNav, PlatformTabs } from 'docComponents';
import {
  AndroidTab,
  AppleTab,
  OtherTab,
  ReactNativeTab,
  WebTab,
} from '../platforms/_index.js';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="code"
      title="Team Avatar"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Avatars" tierThree="Team" />

      <PlatformTabs>
        <WebTab type="web" avatarType="team" />
        <ReactNativeTab type="reactnative" avatarType="team" />
        <AndroidTab type="android" />
        <AppleTab type="apple" />
        <OtherTab type="other" />
      </PlatformTabs>
    </PageWithSubNav>
  );
};

export default IndexPage;
